import React from "react";
import classNames from "classnames";
import { Progress } from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


const ProgressBar = ({ value }) => {
    const classes = classNames(
        "text-white",
        "m-0",
        "p-0",
        "h6"
    );

    return (
        <>
            <Progress
                theme={value < 100 ? "primary" : "success"}
                style={{ height: "20px" }}
                width="100"
                className="mb-3 mr-2 ml-2"
                value={value}
                barClassName="h-100"
                striped={value < 100 ? true : false}
            >
                <p className={classes}>
                    <strong>{value < 10 ? "" : `${value}%`}</strong>
                </p>
            </Progress>
        </>
    )
};

ProgressBar.propTypes = {
    value: PropTypes.number

};

ProgressBar.defaultProps = {
    value: 5
};

const mapStateToProps = state => {
    return {
        value: state.nav.progress
    }
}

export default connect(mapStateToProps)(ProgressBar);
