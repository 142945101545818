import React from "react";
import { 
  Container,
  Row,
} from "shards-react";
import { withRouter } from "react-router-dom";

import ScrollToTop from "../components/common/ScrollToTop";
import PageTitle from "../components/common/PageTitle";
import BillingInformation from '../components/components-overview/BillingInformation/BillingInformation';
import FacebookTracking from "../components/facebook-tracking/FacebookTracking";

const CheckoutView = ({ cart }) => (
  <Container fluid className="main-content-container">

    {/* facebook tracking pixel */}
    <FacebookTracking trackingString="InitiateCheckout" />
    {/* handle scrolling to top */}
    <ScrollToTop />
    
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Checkout" subtitle="Complete your purchase" icon="shopping_cart" className="text-sm-left mb-3" />
    </Row>
    <BillingInformation />
  </Container>
)

export default withRouter(CheckoutView);
