import React from "react";
import {
  Row,
  Col,
  Form,
  Card,
  CardHeader,
  ListGroup, 
  ListGroupItem,
  FormRadio,
  Button
} from "shards-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios"; 

import NavForward from "../../common/NavForward";
import NavBackward from "../../common/NavBackward";

import { 
  setLlc, 
  setPersonal, 
  setMembers, 
  setCart, 
  setProgress,
  setLeadId
} from "../../../redux/actions";

import TextInput from "../../form-items/TextInput";
import StateSelect from "../../form-items/StateSelect";
import CorpTypeSelect from "../../form-items/CorpTypeSelect";
import CorpFunctionSelect from "../../form-items/CorpFunctionSelect";
import DatePickerInput from "../../form-items/DatePickerInput";
import FormValidate from "../../common/FormValidate";
import BusinessNameValidation from "../../../validation/BusinessNameValidation";
import SimpleValidation from "../../../validation/SimpleValidation";
import Modal from "../AddMemberModal/AddMemberModal";
import ShareSlider from "../../form-items/ShareSlider";
import NameValidation from "../../../validation/NameValidation";
import EmailValidation from "../../../validation/EmailValidation";
import PhoneValidation from "../../../validation/PhoneValidation";
import CityValidation from "../../../validation/CityValidation";
import ZipValidation from "../../../validation/ZipValidation";
import BirthdayValidation from "../../../validation/BirthdayValidation";

class BusinessInformation extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    // business information
    businessName: this.props.llcName,
    businessNameValid: true,
    businessNameAlt: this.props.llcNameAlt,
    businessNameAltValid: true,
    businessNameAlt2: this.props.llcNameAlt2,
    businessFunction: this.props.llcFunction,
    businessFunctionValid: true,
    businessFunctionExplained: this.props.llcFunctionExplained,
    businessFunctionExplainedValid: true,
    businessType: this.props.llcType,
    businessTypeValid: true,
    businessState: this.props.llcState,
    businessStateValid: true,
    fee: "",
    expedite: "",
    expedited: false,
    // personal information
    firstName: this.props.firstName,
    firstNameValid: true,
    lastName: this.props.lastName,
    lastNameValid: true,
    email: this.props.email,
    emailValid: true,
    phone: this.props.phone,
    phoneValid: true,
    address: this.props.address,
    addressValid: true,
    city: this.props.city,
    cityValid: true,
    persState: this.props.persState,
    persStateValid: true,
    zip: this.props.zip,
    zipValid: true,
    birthday: this.props.birthday,
    birthdayValid: true,
    citizen: this.props.citizen,
    open: false,
    editing: {},
    editingIndex: null,
    llcShare: this.props.llcShare,
    llcShareTotal: 0,
    llcShareTotalError: false
  }

  componentDidMount() {
    this.props.setProgress(30)
    // check to see if there is a variable in the state already saved
    
    if(this.props.cart[1] && this.props.cart[1].id === 0) {
      const fee = this.props.cart[1].fee
      const expedite = this.props.cart[1].expedite;
      const expedited = this.props.cart[1].expedited;
      this.setState({
        fee: fee,
        expedite: expedite,
        expedited: expedited
      })
    } else if (this.cartContainsPackage() && this.state.businessState && this.state.businessType)  {
      let { fee, expedite } = this.checkFees(this.state.businessState, this.state.businessType)
      this.setState({
        fee: fee,
        expedite: expedite
      })
    }

  }

  handleChange = (event) => {
    const myStateObj = {};

    let { fee, expedite } = this.checkFees(
      event.target.name === 'businessState' ? event.target.value : this.state.businessState, 
      event.target.name === 'businessType' ? event.target.value : this.state.businessType)
    
    myStateObj[event.target.name] = event.target.value;
    myStateObj.fee = fee;
    myStateObj.expedite = expedite;
    
    this.setState(myStateObj)
  }

  handleBlur = (event, validationFunction) => {
    if (validationFunction(event.target.value)) {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = true;
      this.setState(myStateObj)
    } else {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = false;
      this.setState(myStateObj)
    }
  }

  cartContainsPackage = () => {
    let calculate = false;
    this.props.cart.forEach(item => {
      if(item.id >=2 && item.id <=4) {
        calculate = true
      }
    })
    return calculate;
  }

  checkFees = (businessState, businessType) => {
    let fee = '';
    let expedite = '';
    // calculate the costs of the LLC filing and add it to the cart 
    if(businessState && businessType && this.cartContainsPackage()) {
      fee = this.props.filingFees[businessState][businessType === "LLC" ? "LLC" : "corp"].fee;
      expedite =  this.props.filingFees[businessState][businessType === "LLC" ? "LLC" : "corp"].expedite;
    }
    return { fee, expedite };
  }
  
  toggleModel = () => {
    this.setState({
      open: !this.state.open
    })
  }

  deleteMember = index => {
    const memberCopy = [...this.props.members]
    memberCopy.splice(index, 1)
    this.props.setMembers(memberCopy)
  }

  addMember = () => {
    this.setState({
      editing: {},
      editingIndex: null,
      open: !this.state.open
    })
  }

  setEdit = index => {
    this.setState({
      editing: this.props.members[index],
      editingIndex: index,
      open: !this.state.open
    })
  }

  setLLCShare = value => {
    const myStateObj = {};
    myStateObj["llcShare"] = parseInt(value[0]);
    this.setState(myStateObj)
  }

  validate = () => {
    const validators = [
      "businessName",
      "businessNameAlt",
      "businessFunction",
      "businessFunctionExplained",
      "businessState",
      "businessType",
      "firstName",
      "lastName",
      "email",
      "phone",
      "address",
      "city",
      "persState",
      "zip",
      "birthday"
    ]

    const llcShareTotal = this.state.llcShare + this.props.members.reduce((accum, val) => {
      return parseInt(val.llcShare) + parseInt(accum)
    }, 0)

    if(llcShareTotal !== 100 && llcShareTotal !== 99) {
      this.setState({
        llcShareTotal: llcShareTotal,
        llcShareTotalError: true
      })
      return
    }

    this.setState({
      llcShareTotal: llcShareTotal,
      llcShareTotalError: false
    })

    // validate the form for empty items or false values
    const valid = FormValidate(validators, this);

    if (valid) {

      // if no lead ID saved
      if(localStorage.getItem("incFastNowLeadID") === null) {

        const source_desc = localStorage.getItem("sourceDescLeadId");
        // make a lead call
        axios.post(`${process.env.REACT_APP_API_URL}/lead`, {
          pers_first: this.state.firstName,
          pers_last: this.state.lastName,
          pers_email: this.state.email,
          pers_phone: this.state.phone || "",
          source_desc: source_desc || "",
          appKey: 'tQAC9ZLu-X7aKR6evq-TryFMMsM5P-fLtF7Eaqq5-z6bh3GJpY2D'
        }).then(resp => {
          // setting the lead in local storage
          localStorage.setItem('incFastNowLeadID', resp.data)

          // set the lead in the store
          this.props.setLeadId({
            leadId: resp.data
          })
        }).catch(err => {
          console.log(err)
        })
      } else {
        const leadIdFromStore = localStorage.getItem("incFastNowLeadID");
        this.props.setLeadId({
          leadId: leadIdFromStore
        }) 
      }
            
      this.props.setLlc({
        name: this.state.businessName,
        alt_name: this.state.businessNameAlt,
        alt_name_2: this.state.businessNameAlt2,
        function: this.state.businessFunction,
        functionExplained: this.state.businessFunctionExplained,
        state: this.state.businessState,
        type: this.state.businessType
      })

      // deleting out any pre-existing expiditing or fees
      const cartCopy = this.props.cart.filter((item, i) => {
        // 0 is for a preselected state and 1 is for a preselected expidited shipping
        if (item.id !== 0 && item.id !== 1) {
          return item
        } else {
          return false
        }
      })

      if(this.cartContainsPackage()) {
        let { fee } = this.checkFees(this.state.businessState, this.state.businessType)
        // if we are going to expedite, add it to the array
        if(this.state.expedited) {
          cartCopy.splice(1, 0, {
            id: 1,
            title: "Expedited Processing",
            description: "Expedited processing for your business filing.",
            price: this.state.expedite
          })
        }
        if(fee) {
          cartCopy.splice(1, 0, {
            id: 0,
            title: "State Filing Fee",
            description: "The cost to set up this business with the state.",
            price: this.state.fee,
            expedite: this.state.expedite,
            expedited: this.state.expedited,
            fee: fee
          })
        }
      }

      this.props.setCart(cartCopy)
      this.props.setPersonal({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        city: this.state.city,
        state: this.state.persState,
        zip: this.state.zip,
        birthday: this.state.birthday,
        citizen: this.state.citizen,
        llcShare: this.state.llcShare
      })
      
      NavForward(this.props);
    }
  }

  
  back = () => {
    NavBackward(this.props);
  }

  toggleexpedite = () => {
    this.setState({
      expedited: !this.state.expedited
    })
  }

  render() {
    return(
      <Row>
        <Col>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Bussiness Details</h6>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3 font-weight-normal">
                    <Row>
                      <Col>
                        <p className="">The legal name of a company is <strong>required</strong> to be unique from other businesses who are registered within the state. The business name options you request will be researched in the order provided to ensure they are available for registration. We will contact you in the event none of them are available.</p>
                      </Col>
                    </Row>
                    <Form>
                      <Row form>
                        <Col className="form-group" md="6" sm="12">
                          <TextInput 
                            label="Business Name" 
                            formFeedback="Please fill out the business name" 
                            name="businessName"
                            placeholder="Business Name"
                            valid={this.state.businessNameValid}
                            value={this.state.businessName || ""}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={BusinessNameValidation}
                          />
                        </Col>
                        <Col className="form-group" md="6" sm="12">
                          <TextInput 
                            label="Business Name Alternate" 
                            formFeedback="Please fill out the business name" 
                            name="businessNameAlt"
                            placeholder="Business Name Second Choice"
                            valid={this.state.businessNameAltValid}
                            value={this.state.businessNameAlt || ""}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={BusinessNameValidation}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col className="form-group" md="6" sm="12">
                          <TextInput 
                            label="Business Name Second Alternate (Not Required)" 
                            formFeedback="Please fill out the business name" 
                            name="businessNameAlt2"
                            placeholder="Business Name Choice Alternate"
                            valid={true}
                            value={this.state.businessNameAlt2 || ""}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={BusinessNameValidation}
                          />
                        </Col>
                        <Col className="form-group" md="6" sm="12">
                          <CorpFunctionSelect 
                            name="businessFunction"
                            valid={this.state.businessFunctionValid}
                            value={this.state.businessFunction || ""}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={SimpleValidation}
                            list="function-list"
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col className="form-group" sm="12">
                          <TextInput 
                            label="What does your business do? (e.x. if you are in sales, what do you sell?)" 
                            formFeedback="Please tell us what your business will do" 
                            name="businessFunctionExplained"
                            placeholder="What does your business do? (e.x. if you are in sales, what do you sell?)"
                            valid={this.state.businessFunctionExplainedValid}
                            value={this.state.businessFunctionExplained || ""}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={SimpleValidation}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col className="form-group" md="6" sm="12">
                          <CorpTypeSelect 
                            name="businessType"
                            value={this.state.businessType || ""}
                            valid={this.state.businessTypeValid}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={SimpleValidation}
                          />
                        </Col>
                        <Col className="form-group" md="6" sm="12">
                          <StateSelect
                            label="State"
                            formFeedback="Please select your state" 
                            name="businessState"
                            value={this.state.businessState || ""}
                            valid={this.state.businessStateValid}
                            onChangeFunc={this.handleChange}
                            onBlurFunc={this.handleBlur}
                            validationFunction={SimpleValidation}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3" sm="12"></Col>
                        <Col md="6" sm="12">
                          <p className="text-center mt-2 mb-1"><strong>{this.state.fee ? `State filing fee: $${this.state.fee}` : ""}</strong></p>
                          {this.state.expedite ? <p className="text-center">Would you like to add expedited processing for ${this.state.expedite}?    {!this.state.expedited ? <Button className="ml-2" outline onClick={this.toggleexpedite}>Add</Button> : <Button className="ml-2" theme="danger" outline onClick={this.toggleexpedite}>Remove</Button>}</p> : null}
                        </Col>
                        <Col md="3" sm="12"></Col>
                      </Row>
                    </Form>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Member Information</h6>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3 font-weight-normal">
                    <Row>
                      <Col>
                        {this.state.open ? <Modal open={this.state.open} editingIndex={this.state.editingIndex} editing={this.state.editing} toggle={this.toggleModel} setMembers={this.props.setMembers} memberList={this.props.members} /> : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-left">We may need to contact you to collect more information. Please fill out this form so that we know how to contact you.</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form>
                          <Row form>
                            {/* First Name */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="First Name" 
                                formFeedback="Please fill out your first name" 
                                name="firstName"
                                placeholder="First Name"
                                valid={this.state.firstNameValid}
                                value={this.state.firstName || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={NameValidation}
                              />
                            </Col>
                            {/* Last Name */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="Last Name" 
                                formFeedback="Please fill out your last name" 
                                name="lastName"
                                placeholder="Last Name"
                                valid={this.state.lastNameValid}
                                value={this.state.lastName || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={NameValidation}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            {/* Email */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="Email" 
                                formFeedback="Please fill out your email" 
                                name="email"
                                placeholder="Email Address"
                                type="email"
                                valid={this.state.emailValid}
                                value={this.state.email || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={EmailValidation}
                              />
                            </Col>
                            {/* Password */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="Phone" 
                                formFeedback="Please fill out your phone number" 
                                name="phone"
                                placeholder="Phone Number"
                                type="tel"
                                valid={this.state.phoneValid}
                                value={this.state.phone || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={PhoneValidation}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col className="form-group">
                              <TextInput 
                                label="Address" 
                                formFeedback="Please fill out your address" 
                                name="address"
                                placeholder="Address"
                                valid={this.state.addressValid}
                                value={this.state.address || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={SimpleValidation}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            {/* City */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="City" 
                                formFeedback="Please fill out your city" 
                                name="city"
                                placeholder="City"
                                valid={this.state.cityValid}
                                value={this.state.city || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={CityValidation}
                              />
                            </Col>
                            {/* State */}
                            <Col md="4" className="form-group">
                              <StateSelect
                                label="State"
                                formFeedback="Please select your state" 
                                name="persState"
                                valid={this.state.persStateValid} 
                                value={this.state.persState || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={SimpleValidation}
                              />
                            </Col>
                            {/* Zip Code */}
                            <Col md="2" className="form-group">
                              <TextInput 
                                label="Zip" 
                                formFeedback="Please fill out your zip code" 
                                name="zip"
                                placeholder="Zip Code"
                                valid={this.state.zipValid}
                                value={this.state.zip || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={ZipValidation}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="4" className="form-group">
                              <ShareSlider 
                                label="Business Percentage Share"
                                name="llcShare"
                                start={[this.state.llcShare]}
                                onChange={this.setLLCShare}
                              />
                            </Col>
                            <Col md="1" />
                            <Col md="3">
                              <fieldset>
                                <label className="mb-3">Are you a US citizen?</label>
                                <FormRadio value="true" checked={this.state.citizen === "true"} name="citizen" onChange={() => {this.setState({citizen: "true"})}}>Yes</FormRadio>
                                <FormRadio value="false" name="citizen" checked={this.state.citizen === "false"} onChange={() => {this.setState({citizen: "false"})}}>No</FormRadio>
                              </fieldset>
                            </Col>
                            <Col md="4" className="form-group">
                              <DatePickerInput
                                label="Birthday"
                                formFeedback="Please enter your birthday"
                                name="birthday"
                                placeholder="MM/DD/YYYY"
                                valid={this.state.birthdayValid}
                                value={this.state.birthday}
                                onChangeFunc={date => this.setState({birthday: date})}
                                onBlurFunc={this.handleBlur}
                                validationFunction={BirthdayValidation}
                                maxDate={new Date()}
                                minDate={new Date("1/1/1900")}
                              />
                            </Col>
                          </Row>
                        </Form>
                        <Row>
                          <Col>
                            <h4>Members</h4>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                            
                            {this.props.members.map((item, index) => {
                              return (                                  
                                <Col md="4" className="mb-4" key={index}>
                                  <p className="mb-0"><strong>Name:</strong> {item.firstName} {item.lastName}</p>
                                  <p className="mb-0"><strong>Email:</strong> {item.email}</p>
                                  <p className="mb-0"><strong>Phone:</strong> {item.phone}</p>
                                  <p className="mb-0"><strong>Address:</strong> {item.address}</p>
                                  <p className="mb-0">{item.city}, {item.persState} {item.zip}</p>
                                  <p className="mb-1"><strong>Business Share:</strong> {item.llcShare}%</p>
                                  <p className="mb-1"><strong>Citizen:</strong> {item.citizen === "true" ? "Yes" : "No"}</p>
                                  <p className="mb-1"><strong>Birthday:</strong> {item.birthday.toString().split(" ").slice(1,4).join(" ")}</p>
                                  <Button outline size="sm" onClick={() => this.setEdit(index)}>Edit</Button>
                                  <Button className="ml-2" outline size="sm" theme="danger" onClick={() => this.deleteMember(index)}>Delete</Button>
                                </Col>
                              )
                            })}
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            {this.props.members.length < 5 ? <Button outline onClick={this.addMember}>Add a member</Button> : null}
                          </Col>
                        </Row>
                        {this.state.llcShareTotalError ? <Row>
                          <Col>
                            <p className="text-danger">There is an error with the business shares, they must equal 100% total. Right now, the shares are equal to <strong>{this.state.llcShareTotal}</strong>.</p>
                          </Col>
                        </Row> : null}
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="mb-2 mr-1" onClick={this.back}>Back</Button>
              <Button className="mb-2 mr-1" onClick={this.validate}>Next</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    nav: state.nav.navItems,
    cart: state.cart.cart,

    // business props
    llcState: state.llc.state,
    llcType: state.llc.type,
    llcName: state.llc.name,
    llcNameAlt: state.llc.alt_name,
    llcNameAlt2: state.llc.alt_name_2,
    llcFunction: state.llc.function,
    llcFunctionExplained: state.llc.functionExplained,
    filingFees: state.cart.filingFees,

    // personal props
    firstName: state.personal.personal.firstName,
    lastName: state.personal.personal.lastName,
    email: state.personal.personal.email,
    phone: state.personal.personal.phone,
    address: state.personal.personal.address,
    city: state.personal.personal.city,
    persState: state.personal.personal.state,
    zip: state.personal.personal.zip,
    birthday: state.personal.personal.birthday,
    citizen: state.personal.personal.citizen,
    llcShare: state.personal.personal.llcShare,
    members: state.llc.members,
  }
}

const mapDispatchToProps = dispatch => ({
  setLlc: llcInformation => dispatch(setLlc(llcInformation)),
  setProgress: progress => dispatch(setProgress(progress)),
  setPersonal: personalInformation => dispatch(setPersonal(personalInformation)),
  setMembers: personalInformation => dispatch(setMembers(personalInformation)),
  setCart: item => dispatch(setCart(item)),
  setLeadId: id => dispatch(setLeadId(id))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessInformation));
