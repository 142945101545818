export default [
  {
    id: -1,
    title: "Unlimited Name Search",
    description: "Two businesses cannot have the same name. We will conduct a name search with the state database and will work with you as long as needed to find an available company name that you love that is unique to you and your business.",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "search",
    actionable: false
  },
  {
    id: -1,
    title: "Articles of Organization or Incorporation",
    description: "Articles of Organization or Incorporation are needed for every business. They may be required to show proof of your business formation to banks and other entities. We will properly prepare your organization documents, and file them with the state of formation.",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "note",
    actionable: true
  },
  {
    id: -1,
    title: "Electronic Delivery of Documents",
    description: "We will deliver your incorporation documents to you digitally, so you can download and save them for your quick access.",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "email",
    actionable: false
  },
  {
    id: -1,
    title: "30 Min Tax Consultation",
    description: "Business tax advice right from the beginning, so you are prepared with a strategy that can help you save on your business taxes. You will receive a 30 minutes consultation with a tax professional who can answer questions and concerns about your company’s tax commitments",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "perm_phone_msg",
    actionable: true
  },
  {
    id: -1,
    title: "Unlimited Customer Support",
    description: "Business specialists at your fingertips. We are always here to help, just give us a call or send us an email. We strive to provide friendly, fast and knowledgeable customer service.",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "phone",
    actionable: false
  },
  {
    id: -1,
    title: "Statement and Resignation of Organizer",
    description: "The Statement and Resignation of Organizer is a document signed by the Organizer which identifies the initial members of the Company. This provides official documentation stating who members or managers (if applicable) are and shows proof of ownership.",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: "receipt",
    actionable: true
  },
  {
    id: -1,
    title: "Set you up as Your Own Resident Agent",
    description: "We will set you up as your own resident agent. The resident agent accepts tax and legal documents on behalf of your business. Most states require you to have a Resident Agent. This agent can be yourself, a colleague, or a professional service. You can be your own registered agent, if you are 18 years or older, have a physical address in the state where business is conducted, are always available during normal business hours to receive service of process in person",
    prices: [true, true, true],
    price: 0,
    reOccur: false,
    addable: false,
    icon: 'face',
    actionable: true
  },
  {
    id: 5,
    title: "EIN",
    description: "Employer Identification Number/ Tax ID is a nine-digit number assigned by the IRS. It is used to identify taxpayers. This is highly recommended because an EIN is required to file your business taxes and open a business bank account.",
    prices: [true, true, 70],
    price: 70,
    reOccur: false,
    addable: true,
    icon: "gavel",
    actionable: true
  },
  {
    id: 6,
    title: "Custom Operating Agreement",
    description: "The operating agreement is an important document, where you will define your company’s structure and rules. It is a private agreement between the members/owners of the company, and it details the ownership, rights and responsibilities of each of the members. We will provide an agreement that covers all of the most commonly used provisions and it will also be customized based on your specific company information.",
    prices: [true, true, 40],
    price: 40,
    reOccur: false,
    addable: true,
    icon: "web_asset",
    actionable: true
  },
  {
    id: 7,
    title: "Banking Resolutions",
    description: "The banking resolution document will authorize the opening of a bank account and designate who has signature authority on the account.",
    prices: [true, true, 40],
    price: 40,
    reOccur: false,
    addable: true,
    icon: "account_balance",
    actionable: true
  },
  {
    id: 8,
    title: "Preparation of Form 2553",
    description: "IRS Form 2553, \"Election By a Small Business Corporation\", is required to be filed with the IRS to switch a C Corporation to S Corporation status for purposes of federal taxation. In the same way as a corporation elects corporation tax status, an LLC may elect S corporation tax status by filing IRS Form 2553 with the IRS. The election must be made no more than two months and 15 days after the beginning of the tax year when the election is to go into effect.",
    prices: [true, 50, 50],
    price: 50,
    reOccur: false,
    addable: true,
    icon: "description",
    actionable: true
  },
  {
    id: 9,
    title: "Registered Agent Service: For 1 Full Year",
    description: "As your Registered Agent, we will use our address to accept your official legal correspondence and then deliver it to you. This service limits the personal information that is available to third parties and eliminates junk mail. Important: This service will be provided for 1 year. At the end of the year you will be charged the annual fee of $149.00. You can cancel this service any time before payment is processed.",
    prices: [true, 149, 149],
    price: 149,
    reOccur: "/yr",
    addable: true,
    icon: "how_to_reg",
    actionable: true
  },
  {
    id: 14,
    title: "Business License",
    description: "Business licenses are an important part of every business. What makes these tricky is the wide variety of requirements based on location and business type. By choosing this option IncFastNow and our Partners can ensure you get the exact licenses you need.",
    prices: [99, 99, 99],
    price: 99,
    reOccur: false,
    addable: true,
    icon: "public",
    actionable: true
  },
  {
    id: 11,
    title: "Domain + Privacy",
    description: "Professional branding process to identify and secure one domain name for your brand with full domain privacy and protection which protects against domain hijacking and malicious transfer. Comes with unlimited domain email support.",
    prices: [60, 60, 60],
    price: 60,
    reOccur: "/yr",
    addable: true,
    icon: "language",
    actionable: true
  },
  {
    id: 12,
    title: "Emails",
    description: "One email account designed for business professional emails like yourname@example.com or sales@example.com. Your data, devices and users are protected and all your emails & files are automatically backed up in the cloud. You also get enterprise-grade access control with security key enforcement and unlimited email support.",
    prices: [15, 15, 15],
    price: 15,
    reOccur: "/mo",
    addable: true,
    icon: "drafts",
    actionable: true
  }
]
