import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    Modal,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    Form,
    Button,
    FormRadio
} from "shards-react";

import TextInput from "../../form-items/TextInput";
import StateSelect from "../../form-items/StateSelect";
import FormValidate from "../../common/FormValidate";
import ShareSlider from "../../form-items/ShareSlider";
import DatePickerInput from "../../form-items/DatePickerInput";
import NameValidation from "../../../validation/NameValidation";
import EmailValidation from "../../../validation/EmailValidation";
import PhoneValidation from "../../../validation/PhoneValidation";
import CityValidation from "../../../validation/CityValidation";
import SimpleValidation from "../../../validation/SimpleValidation";
import ZipValidation from "../../../validation/ZipValidation";
import BirthdayValidation from "../../../validation/BirthdayValidation";

class AddMemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    firstName: this.props.editing.firstName,
    firstNameValid: true,
    lastName: this.props.editing.lastName,
    lastNameValid: true,
    email: this.props.editing.email,
    emailValid: true,
    phone: this.props.editing.phone,
    phoneValid: true,
    address: this.props.editing.address,
    addressValid: true,
    city: this.props.editing.city,
    cityValid: true,
    persState: this.props.editing.persState,
    persStateValid: true,
    zip: this.props.editing.zip,
    zipValid: true,
    llcShare: this.props.editing.llcShare || 50,
    citizen: this.props.citizen || "true",
    birthday: this.props.editing.birthday,
    birthdayValid: true,
  }

  handleChange = (event) => {
    const myStateObj = {};
    myStateObj[event.target.name] = event.target.value;
    this.setState(myStateObj)
  }

  handleBlur = (event, validationFunction) => {
    if (validationFunction(event.target.value)) {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = true;
      this.setState(myStateObj)
    } else {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = false;
      this.setState(myStateObj)
    }
  }

  setLLCShare = value => {
    const myStateObj = {};
    myStateObj["llcShare"] = parseInt(value[0]);
    this.setState(myStateObj)
  }

  validate = () => {
    const validators = ["firstName", "lastName", "email", "phone", "address", "city", "persState", "zip", "birthday"]
    // validate the form for empty items or false values
    let valid = FormValidate(validators, this);

    if (valid) {
      // add the member
      const memberList = [...this.props.memberList]
      const memberObj = {
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "email": this.state.email,
        "phone": this.state.phone,
        "address": this.state.address,
        "city": this.state.city,
        "persState": this.state.persState,
        "zip": this.state.zip,
        "llcShare": this.state.llcShare,
        "citizen": this.state.citizen,
        "birthday": this.state.birthday
      }
      if(typeof(this.props.editingIndex) === "number"){
        memberList.splice(this.props.editingIndex, 1, memberObj)
      } else {
        memberList.push(memberObj)
      }
      this.props.setMembers(memberList)
      this.props.toggle()
    }
  }

  render () {
    return (
      <Modal
        size="lg"
        className="mt-5 mb-5"
        open={this.props.open}
        onHide={() => {this.setState({ show: false })}}
        toggle={() => {}}
      >
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Add a member</h6>
          </CardHeader>
          <CardBody className="pt-0">
            <Form>
              <Row form className="py-2">
                {/* First Name */}
                <Col md="6" className="form-group">
                  <TextInput 
                    label="First Name" 
                    formFeedback="Please fill out your first name" 
                    name="firstName"
                    placeholder="First Name"
                    valid={this.state.firstNameValid}
                    value={this.state.firstName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <TextInput 
                    label="Last Name" 
                    formFeedback="Please fill out your last name" 
                    name="lastName"
                    placeholder="Last Name"
                    valid={this.state.lastNameValid}
                    value={this.state.lastName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
              </Row>
              <Row form>
                {/* Email */}
                <Col md="6" className="form-group">
                  <TextInput 
                    label="Email" 
                    formFeedback="Please fill out your email" 
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    valid={this.state.emailValid}
                    value={this.state.email || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={EmailValidation}
                  />
                </Col>
                {/* Phone number */}
                <Col md="6" className="form-group">
                  <TextInput 
                    label="Phone" 
                    formFeedback="Please fill out your phone number" 
                    name="phone"
                    placeholder="Phone Number"
                    type="tel"
                    valid={this.state.phoneValid}
                    value={this.state.phone || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={PhoneValidation}
                  />
                </Col>
              </Row>
              <Row form>
                <Col className="form-group">
                  <TextInput 
                    label="Address" 
                    formFeedback="Please fill out your address" 
                    name="address"
                    placeholder="Address"
                    valid={this.state.addressValid}
                    value={this.state.address || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={SimpleValidation}
                  />
                </Col>
              </Row>
              <Row form>
              {/* City */}
                <Col md="6" className="form-group">
                  <TextInput 
                    label="City" 
                    formFeedback="Please fill out your city" 
                    name="city"
                    placeholder="City"
                    valid={this.state.cityValid}
                    value={this.state.city || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={CityValidation}
                  />
                </Col>
                {/* State */}
                <Col md="4" className="form-group">
                  <StateSelect
                    label="State"
                    formFeedback="Please select your state" 
                    name="persState"
                    value={this.state.persState || ""}
                    valid={this.state.persStateValid} 
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={SimpleValidation}
                  />
                </Col>
                {/* Zip Code */}
                <Col md="2" className="form-group">
                  <TextInput 
                    label="Zip" 
                    formFeedback="Please fill out your zip code" 
                    name="zip"
                    placeholder="Zip Code"
                    valid={this.state.zipValid}
                    value={this.state.zip || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={ZipValidation}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4" className="form-group">
                  <ShareSlider 
                    label="Business Percentage Share"
                    name="llcShare"
                    start={[this.state.llcShare]}
                    onChange={this.setLLCShare}
                  />
                </Col>
                <Col md="1" />
                <Col md="3" className="form-group">
                <fieldset>
                  <label className="mb-3">Are you a US citizen?</label>
                  <FormRadio value="true" checked={this.state.citizen === "true"} name="citizen" onChange={() => {this.setState({citizen: "true"})}}>Yes</FormRadio>
                  <FormRadio value="false" name="citizen" checked={this.state.citizen === "false"} onChange={() => {this.setState({citizen: "false"})}}>No</FormRadio>
                </fieldset>
                </Col>
                <Col md="4" className="form-group">
                  <DatePickerInput
                    label="Birthday"
                    formFeedback="Please enter your birthday."
                    name="birthday"
                    placeholder="MM/DD/YYYY"
                    valid={this.state.birthdayValid}
                    value={this.state.birthday}
                    onChangeFunc={date => this.setState({birthday: date})}
                    onBlurFunc={this.handleBlur}
                    validationFunction={BirthdayValidation}
                    maxDate={new Date()}
                    minDate={new Date("1/1/1900")}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Button onClick={this.validate}>{typeof(this.props.editingIndex) === "number" ? "Save" : "Add"}</Button>
            <Button className="ml-2" theme="danger" outline onClick={this.props.toggle}>Cancel</Button>
          </CardFooter>
        </Card>
      </Modal>
    )
  }
}

AddMemberModal.propTypes = {
  editing: PropTypes.object
};

AddMemberModal.defaultProps = {
  editing: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    persState: "",
    zip: "",
    birthday: null
  }
};

export default AddMemberModal;
