export const SET_CART = "SET_CART"
export const SET_SELECTED_PACKAGE = "SET_SELECTED_PACKAGE"

export const SET_PROGRESS = "SET_PROGRESS"

export const SET_PERSONAL = "SET_PERSONAL"
export const SET_BILLING = "SET_BILLING"

export const SET_LLC = "SET_LLC"
export const SET_LLC_INTRO = "SET_LLC_INTRO"
export const SET_LLC_PROCESS = "SET_LLC_PROCESS"
export const SET_MEMBERS = "SET_MEMBERS"

export const SET_LEAD_ID = "SET_LEAD_ID"
export const SET_PROMO_CODE = "SET_PROMO_CODE"
export const SET_DISCOUNT_AMOUNT = "SET_DISCOUNT_AMOUNT"

export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID"