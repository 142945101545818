import React from "react";
import { 
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form
} from "shards-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setBilling, setProgress } from "../../../redux/actions";

import Cart from "../CartInformation/CartInformation";
import PromoCode from "../PromoCode/PromoCode";

import NavForward from "../../common/NavForward";
import NavBackward from "../../common/NavBackward";

import TextInput from "../../form-items/TextInput";
import StateSelect from "../../form-items/StateSelect";
import FormValidate from "../../common/FormValidate";
import CardValidation from "../../../validation/CardValidation";
import ExpirationValidation from "../../../validation/ExpirationValidation";
import CVVValidation from "../../../validation/CVVValidation";
import NameValidation from "../../../validation/NameValidation";
import CityValidation from "../../../validation/CityValidation";
import SimpleValidation from "../../../validation/SimpleValidation";
import ZipValidation from "../../../validation/ZipValidation";

import CalculateDiscount from "../../common/CalculateDiscount";

class BillingInformation extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    card: '',
    cardValid: true,
    exp: '',
    expValid: true,
    cvv: '',
    cvvValid: true,
    firstName: this.props.firstName,
    firstNameValid: true,
    lastName: this.props.lastName,
    lastNameValid: true,
    address: this.props.address,
    addressValid: true,
    city: this.props.city,
    cityValid: true,
    billState: this.props.billState,
    billStateValid: true,
    zip: this.props.zip,
    zipValid: true,
    cartTotal: 0
  }

  componentWillReceiveProps(incProps) {
    const discountObj = CalculateDiscount(this.props.cart, incProps.discount)
    this.setState(discountObj)
  }

  componentDidMount() {
    this.props.setProgress(60)
    const discountObj = CalculateDiscount(this.props.cart, this.props.discount)
    this.setState(discountObj)

  }

  handleChange(event) {
    const myStateObj = {};
    myStateObj[event.target.name] = event.target.value;
    this.setState(myStateObj)
  }

  handleBlur = (event, validationFunction) => {
    if (validationFunction(event.target.value)) {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = true;
      this.setState(myStateObj)
    } else {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = false;
      this.setState(myStateObj)
    }
  }

  copyPersonal = () => {
    const myStateObj = {};
    myStateObj["firstName"] = this.props.firstNamePers;
    myStateObj["lastName"] = this.props.lastNamePers;
    myStateObj["address"] = this.props.addressPers;
    myStateObj["city"] = this.props.cityPers;
    myStateObj["billState"] = this.props.statePers;
    myStateObj["zip"] = this.props.zipPers;
    this.setState(myStateObj)
  }

  validate = () => {
    const validators = ["card", "exp", "cvv", "firstName", "lastName", "address", "city", "billState", "zip"]
    // validate the form for empty items or false values
    let valid = FormValidate(validators, this);

    if (valid) {
      this.props.setBilling({
        card: this.state.card,
        exp: this.state.exp,
        cvv: this.state.cvv,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        address: this.state.address,
        city: this.state.city,
        state: this.state.billState,
        zip: this.state.zip
      })

      NavForward(this.props)
    }
  }

  back = () => {
    NavBackward(this.props)
  }

  render() {
    return (
      <Row>
        <Col>
          <Row>
            <Col md="8" sm="12">
              <Row>
                {/* Card Info */}
                <Col md="12" className="mb-4">
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Card Information</h6>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="p-3 font-weight-normal">
                        <Form>
                          <Row form>
                            {/* Card Number */}
                            <Col md="12" className="form-group">
                              <TextInput 
                                label="Card Number" 
                                formFeedback="Please fill out the card number" 
                                name="card"
                                placeholder="Card Number"
                                valid={this.state.cardValid}
                                value={this.state.card || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                autoComplete="cc-number"
                                maxLength="19"
                                validationFunction={CardValidation}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            {/* Exp Date */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="Expiration Date" 
                                formFeedback="Please fill out the expiration date as 'MM/YY'" 
                                name="exp"
                                placeholder="09/22"
                                valid={this.state.expValid}
                                value={this.state.exp || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={ExpirationValidation}
                              />
                            </Col>
                            {/* CVV */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="CVV" 
                                formFeedback="Please fill out the CVV code" 
                                name="cvv"
                                placeholder="123"
                                valid={this.state.cvvValid}
                                value={this.state.cvv || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={CVVValidation}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <Row>
                {/* Billing Info */}
                <Col md="12" className="mb-4">
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <Row>
                        <Col md="6">
                          <h6 className="m-0">Billing Information</h6>
                        </Col>
                        <Col md="6" className="text-right">
                          <Button size="sm" outline onClick={this.copyPersonal}>Copy Personal Information</Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="p-3 font-weight-normal">
                        <Form>
                          <Row form>
                            {/* First Name */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="First Name" 
                                formFeedback="Please fill out your first name" 
                                name="firstName"
                                placeholder="First Name"
                                valid={this.state.firstNameValid}
                                value={this.state.firstName || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={NameValidation}
                              />
                            </Col>
                            {/* Last Name */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="Last Name" 
                                formFeedback="Please fill out your last name" 
                                name="lastName"
                                placeholder="Last Name"
                                valid={this.state.lastNameValid}
                                value={this.state.lastName || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={NameValidation}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col className="form-group">
                              <TextInput 
                                label="Address" 
                                formFeedback="Please fill out your address" 
                                name="address"
                                placeholder="Address"
                                valid={this.state.addressValid}
                                value={this.state.address || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            {/* City */}
                            <Col md="6" className="form-group">
                              <TextInput 
                                label="City" 
                                formFeedback="Please fill out your city" 
                                name="city"
                                placeholder="City"
                                valid={this.state.cityValid}
                                value={this.state.city || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={CityValidation}
                              />
                            </Col>
                            {/* State */}
                            <Col md="4" className="form-group">
                              <StateSelect 
                                label="State"
                                formFeedback="Please select your state" 
                                name="billState"
                                value={this.state.billState || ""}
                                valid={this.state.billStateValid} 
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={SimpleValidation}
                              />
                            </Col>
                            {/* Zip Code */}
                            <Col md="2" className="form-group">
                              <TextInput 
                                label="Zip" 
                                formFeedback="Please fill out your zip code" 
                                name="zip"
                                placeholder="Zip Code"
                                valid={this.state.zipValid}
                                value={this.state.zip || ""}
                                onChangeFunc={this.handleChange}
                                onBlurFunc={this.handleBlur}
                                validationFunction={ZipValidation}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="4" sm="12">
              {/* Cart */}
              <Row>
                <Col md="12" className="mb-4">
                  <Cart cartItems={this.props.cart} cartTotal={this.state.cartTotal} discount={this.props.discount} />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mb-4">
                  <PromoCode />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="mb-2 mr-1" onClick={this.back}>Back</Button>
              <Button className="mb-2 mr-1" onClick={this.validate}>Order</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

}

const mapStateToProps = state => {
  return {
    firstName: state.personal.billing.firstName,
    lastName: state.personal.billing.lastName,
    address: state.personal.billing.address,
    city: state.personal.billing.city,
    billState: state.personal.billing.state,
    zip: state.personal.billing.zip,
    cart: state.cart.cart,
    // values for copying
    firstNamePers: state.personal.personal.firstName,
    lastNamePers: state.personal.personal.lastName,
    addressPers: state.personal.personal.address,
    cityPers: state.personal.personal.city,
    statePers: state.personal.personal.state,
    zipPers: state.personal.personal.zip,
    // nav
    nav: state.nav.navItems,
    // discount
    discount: state.cart.discount
  }
}

const mapDispatchToProps = dispatch => ({
  setBilling: billingInformation => dispatch(setBilling(billingInformation)),
  setProgress: progress => dispatch(setProgress(progress))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingInformation));
