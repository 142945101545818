import React from "react";
import { 
  Row, 
  Col,
  Button,
  Card,
  CardHeader,
  CardBody
} from "shards-react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import NavForward from "../../common/NavForward";
import CollapseItem from "../CollapseItem/CollapseItem";

import { 
  setCart, 
  setLlcIntro, 
  setPromoCode, 
  setSelectedPackage, 
  setProgress 
} from "../../../redux/actions";

class PricingTable extends React.Component {
  
  state = {
    cart: this.props.cart,
    addons: this.props.addons,
    itemSelected: this.props.selectedPackage
  }

  componentDidMount() {
    this.props.setProgress(5)
    // parse all the URL parameters coming in
    const params = queryString.parse(this.props.location.search)
    // check for type and state of business
    if(params.type || params.state) {
        this.props.setLlcIntro({
        type: params.type || "",
        state: params.state || ""
        })
    }
    // set a storage item for the lead source if there is one
    if(params.leadId) {
        localStorage.setItem("sourceDescLeadId", params.leadId)
    }
    // set a storage item for the promo code if there is one
    if(params.promo) {
        this.props.setPromoCode(params.promo)
    }
    // set a selected package
    if(params.package) {
      this.add_package_to_cart(parseInt(params.package))
    } else {
      this.add_package_to_cart(this.state.itemSelected)
    }
  }

  check_if_in_cart = id => {
    let inCart = false;
    this.state.cart.forEach(item => {
      if (item.id === parseInt(id)) {
        inCart = true;
      }
    })
    return inCart;
  }

  clean_cart = packageId => {
    if (packageId === null) return this.state.cart
    const newCart = []

    this.state.cart.forEach((item) => {
      // 2 is the offset beacuse the packages start with an ID of 2
      // true means that item is included in the package by default 
      // we dont copy an item over if its included in the selected package
      if(item.prices && item.prices[packageId-2] !== true) {
        newCart.push(item)
      }
    })
    return newCart;
  }

  set_column_selected = () => {
    // run through current cart and set selected so that people can see it on the UI
    this.props.cart.forEach(item => {
      if(item.id === 2 || item.id === 3 || item.id === 4) {
        this.setState({itemSelected: item.id})
      }
    })
  }

  add_addon_to_cart = (id) => {
    const cartCopy = [...this.state.cart]

    // find the object we need to add to the cart in the addons
    this.state.addons.forEach(item => {
      if (item.id === id) {
        cartCopy.push(item)
      }
    })

    // set the new cart
    this.setState({
      cart: cartCopy
    })

    this.props.setCart(cartCopy)
  }

  remove_addon_from_cart = (id) => {
    const cartCopy = [...this.state.cart]

    // remove the item with the specified ID
    cartCopy.forEach((item, index) => {
      if (id === item.id) {
        cartCopy.splice(index, 1)
      }
    })
    // set the new cart
    this.setState({
      cart: cartCopy
    })

    this.props.setCart(cartCopy)
  }

  remove_package_from_cart = (id) => {
    const cartCopy = [...this.state.cart]

    cartCopy.forEach((item, i) => {
      // check to make sure we dont have another package in the cart
      if(item.id >= 2 && item.id <= 4) {
        cartCopy.splice(i, 1)
      }
    })

    this.props.setCart(cartCopy)
    this.props.setSelectedPackage(null)
    this.setState({
      itemSelected: null,
      cart: cartCopy
      // adding in a callback function to reset what column is selected
    }, this.set_column_selected())
  }

  add_package_to_cart = (id) => {
    const newCart = this.clean_cart(id)

    newCart.forEach((item, i) => {
      // check to make sure we dont have another package in the cart
      if(item.id >= 2 && item.id <= 4) {
        newCart.splice(i, 1)
      }
    })
    // this sets the original package into our cart
    this.props.packages.forEach(item => {
      if(item.id === id) {
        newCart.splice(0, 0, item)
      }
    })

    this.props.setCart(newCart)
    this.props.setSelectedPackage(id)

    this.setState({
      itemSelected: id,
      cart: newCart
      // adding in a callback function to reset what column is selected
    }, this.set_column_selected())
  }

  forward = () => {
    this.add_package_to_cart(this.state.itemSelected)
    
    if(this.state.cart.length > 0) {
      NavForward(this.props);
    }
  }

  render() {
    return(
      <>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h4 className="m-0 text-center">Pricing Options</h4>
          </CardHeader>
          <CardBody className="p-0">
            <div className="table-responsive">
              <table className="table mb-0">
                <colgroup>
                  <col />
                  <col style={{backgroundColor: "rgba(0,123,255,0.1)"}} />
                  <col />
                </colgroup>
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                    </th>
                    {this.props.packages.map((item, i) => (
                      <th key={i} scope="col" className="border-0 text-center">
                        <span className="d-block">{item.title.split(" ")[0]}</span> ${item.price}
                      </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.state.addons.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{width: "70%"}}>
                          <CollapseItem 
                            title={item.title} 
                            content={item.description} 
                            addable={item.addable} 
                            inCart={() => this.check_if_in_cart(item.id)}
                            icon={item.icon}
                            id={item.id}
                            count={i}
                            removeFunc={this.remove_addon_from_cart}
                            addFunc={this.add_addon_to_cart}
                            />
                        </td>
                        {item.prices.map((price, i) => {
                          if(price !== true) {
                            return <td className="text-center" key={i}><p style={{fontSize: "14px"}} className="mb-0">${price}{item.reOccur ? <span style={{fontSize: "10px"}} className="text-muted">{item.reOccur}</span> : null}</p></td>
                          } else {
                            return <td className="text-center" key={i}><i className="material-icons text-success" style={{fontSize: "24px"}}>done</i></td>
                          }
                        })}
                      </tr>
                    )
                  })}
                  <tr>
                    <td>
                      <p className="mb-0 ml-1 h5">Base Cost</p>
                    </td>
                    {this.props.packages.map((item, i) => (
                      <td key={i} className="text-center">
                        <p className="mb-0 h5"><strong>${item.price}</strong></p>
                      </td>
                      ))
                    }
                  </tr>
                  <tr className="bg-white">
                    <td></td>
                    {this.props.packages.map((item, i) => (
                        <td key={i} className="text-center"  style={{minWidth: '101px'}}>
                          {this.state.itemSelected === item.id ? <Button outline theme="danger" onClick={() => {this.remove_package_from_cart(item.id)}}>Remove</Button> : <Button onClick={() => {this.add_package_to_cart(item.id)}}>Select</Button>}
                        </td>
                      ))
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        <Row>
          <Col>
            {this.state.cart.length > 0 ? <Button className="mb-2" onClick={this.forward}>Next</Button> : <p>Please select at least one item to continue.</p>}
          </Col>
        </Row>  
      </>
    )
  }
}

const mapStateToProps = state => {
  return { 
    packages: state.cart.packages,
    nav: state.nav.navItems,
    cart: state.cart.cart,
    addons: state.cart.addons,
    selectedPackage: state.cart.selectedPackage
  }
}

const mapDispatchToProps = dispatch => ({
  setCart: cart => dispatch(setCart(cart)),
  setProgress: progress => dispatch(setProgress(progress)),
  setLlcIntro: llc => dispatch(setLlcIntro(llc)),
  setPromoCode: promoCode => dispatch(setPromoCode(promoCode)),
  setSelectedPackage: packageId => dispatch(setSelectedPackage(packageId))
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingTable));
