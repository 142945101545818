export default function CalculateDiscount(cart, discount) {
  const beforeDiscount = cart.reduce((accum, val) => {
    return parseInt(val.price) + parseInt(accum)
  }, 0)

  if(discount){
    if(discount > 1) {

      return {
        cartTotal: beforeDiscount - parseInt(discount)
      }
    } else {
      return {
        cartTotal: beforeDiscount - (beforeDiscount * parseFloat(discount))
      }
    }
  } else {
    return {
      cartTotal: beforeDiscount
    }
  }
}