import React from "react";
import { Container } from "shards-react";

import ScrollToTop from "../components/common/ScrollToTop";
import Processing from '../components/components-overview/Processing/Processing';

const ProcessingView = () => (
  <Container fluid className="main-content-container p-0">
    {/* handle scrolling to top */}
    <ScrollToTop />
    <Processing />
  </Container>
);


export default ProcessingView;
