import React from 'react';
import { connect } from "react-redux";

import CalculateDiscount from "./CalculateDiscount";

class DataLayer extends React.Component  {

    componentDidMount() {
        const s = document.createElement('script');
        
        const productArray = []
        this.props.cart.forEach(element => 
            productArray.push({"name" : element.title, "price": element.price, "id": element.id})
        )
        const productsAsAString = JSON.stringify(productArray)

        const totalPrice = CalculateDiscount(this.props.cart, this.props.discount).cartTotal

        s.innerHTML = `
        window.dataLayer = window.dataLayer || [];
            dataLayer.push({
            'event': 'transaction',
            'ecommerce': {
                'purchase': {
                  'actionField': {
                    'id': "${this.props.accountId}",       
                    'revenue':  ${totalPrice},
                  },
                  'products': ${productsAsAString}
                }
              }
        });
        `
        this.instance.appendChild(s);
    
      }

    render() {
        return <div ref={el => (this.instance = el)} />;
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        discount: state.cart.discount,
        accountId: state.cart.accountId
    }
}

export default connect(mapStateToProps)(DataLayer);
