import React from "react";
import axios from 'axios';
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Button,
  Alert
} from "shards-react";
import BeatLoader from "react-spinners/BeatLoader";

import TextInput from "../../form-items/TextInput";
import ReferralSelect from "../../form-items/ReferralSelect";
import FormValidate from "../../common/FormValidate";
import EmailValidation from "../../../validation/EmailValidation";
import NameValidation from "../../../validation/NameValidation";
import PhoneValidation from "../../../validation/PhoneValidation";
import SimpleValidation from "../../../validation/SimpleValidation";

class Referral extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    firstName: "",
    firstNameValid: true,
    lastName: "",
    lastNameValid: true,
    email: "",
    emailValid: true,
    phone: "",
    clientNeed: "",
    clientNeedValid: true,
    referrerCompany: "",
    referrerFirstName: "",
    referrerFirstNameValid: true,
    referrerLastName: "",
    referrerLastNameValid: true,
    processing: false,
    submitted: false,
    error: false
  }

  handleChange = (event) => {
    const myStateObj = {};
    myStateObj[event.target.name] = event.target.value;
    this.setState(myStateObj)
  }

  handleBlur = (event, validationFunction) => {
    if (validationFunction(event.target.value)) {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = true;
      this.setState(myStateObj)
    } else {
      const myStateObj = {};
      myStateObj[event.target.name+"Valid"] = false;
      this.setState(myStateObj)
    }
  }

  validate = () => {

    const validators = ["firstName", "lastName", "email", "referrerFirstName", "referrerLastName", "clientNeed"]
    // validate the form for empty items or false values
    const valid = FormValidate(validators, this)

    if (valid) {

      this.setState({
        processing: true,
        submitted: false,
        error: false
      })

      // make a lead call
      axios.post(`${process.env.REACT_APP_API_URL}/lead`, {
        pers_first: this.state.firstName,
        pers_last: this.state.lastName,
        pers_email: this.state.email,
        pers_phone: this.state.phone || "",
        source: "Other",
        source_desc: this.state.referrerCompany || "",
        description: `Client need: ${this.state.clientNeed}`,
        refered_by: `${this.state.referrerFirstName} ${this.state.referrerLastName}`,
        appKey: 'tQAC9ZLu-X7aKR6evq-TryFMMsM5P-fLtF7Eaqq5-z6bh3GJpY2D'
      }).then(resp => {
        this.setState({
          processing: false,
          submitted: true,
          error: false,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          clientNeed: ""
        })
      }).catch(err => {
        this.setState({
          processing: false,
          submitted: false,
          error: true
        })
      })
    }
  }

  render () {
    return (
      <Card small className="mb-4">
        {this.state.error ? <Alert className="mb-0 bg-danger"><i className="fa fa-info mx-2"></i> We encountered an error. Please try again and if this issue persists, please contact support.</Alert> : null}
        {this.state.submitted ? <Alert className="mb-0 bg-success"><i className="fa fa-info mx-2"></i> Successfully submitted</Alert> : null}
        <Form>
          <ListGroup flush>
            <ListGroupItem className="p-4 mb-3">
              <h5 className="text-primary">Client Information</h5>
              <Row className="form-group">
                {/* First Name */}
                <Col md="6">
                  <TextInput 
                    label="First Name" 
                    placeholder="First Name" 
                    formFeedback="Please fill out the client first name" 
                    name="firstName"
                    valid={this.state.firstNameValid}
                    value={this.state.firstName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6">
                  <TextInput 
                    label="Last Name"
                    placeholder="Last Name"
                    formFeedback="Please fill out the client last name" 
                    name="lastName"
                    valid={this.state.lastNameValid}
                    value={this.state.lastName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                {/* Email */}
                <Col md="6">
                  <TextInput 
                    label="Email"
                    placeholder="Email" 
                    formFeedback="Please fill out the client email" 
                    name="email"
                    type="email"
                    valid={this.state.emailValid}
                    value={this.state.email || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={EmailValidation}
                  />
                </Col>
                <Col md="6">
                  <TextInput 
                    label="Phone"
                    placeholder="Phone"
                    formFeedback="Please fill out the client phone number" 
                    name="phone"
                    type="tel"
                    valid={true}
                    value={this.state.phone || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={PhoneValidation}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col md="6">
                  <ReferralSelect 
                    label="Client Need"
                    placeholder="Client Need" 
                    formFeedback="Please select the client need" 
                    name="clientNeed"
                    valid={this.state.clientNeedValid}
                    value={this.state.clientNeed || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem className="p-4 mb-3 form-group">
              <h5 className="text-primary">Referrer Information</h5>
              <Row className="form-group">
                {/* First Name */}
                <Col md="6">
                  <TextInput 
                    label="First Name"
                    placeholder="First Name"
                    formFeedback="Please fill out your first name" 
                    name="referrerFirstName"
                    valid={this.state.referrerFirstNameValid}
                    value={this.state.referrerFirstName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6">
                  <TextInput 
                    label="Last Name"
                    placeholder="Last Name" 
                    formFeedback="Please fill out your last name" 
                    name="referrerLastName"
                    valid={this.state.referrerLastNameValid}
                    value={this.state.referrerLastName || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={NameValidation}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col md="6">
                  <TextInput 
                    label="Company"
                    placeholder="Company"
                    formFeedback="Please fill out your last name" 
                    name="referrerCompany"
                    valid={true}
                    value={this.state.referrerCompany || ""}
                    onChangeFunc={this.handleChange}
                    onBlurFunc={this.handleBlur}
                    validationFunction={SimpleValidation}
                  />
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem className="p-3 form-group">
              {this.state.processing ? <BeatLoader /> : <Button className="mb-2 mr-1" onClick={this.validate}>Submit</Button>}
            </ListGroupItem>
          </ListGroup>
        </Form>
      </Card>
    )
  }
}



export default Referral;
