export default function FormValidate(validators, context) {

    let valid = true;
    validators.forEach(item => {
      if(context.state[item+"Valid"] !== true || context.state[item] === "" || context.state[item] === undefined || context.state[item] === null) {
        valid = false
        const myStateObj = {};
        myStateObj[item+"Valid"] = false;
        context.setState(myStateObj)
      }
    })
    return valid;
}