import { SET_PERSONAL, SET_BILLING, SET_LEAD_ID } from "../actionTypes";

const initialState = {
    personal: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        birthday: null,
        citizen: "true",
        llcShare: 100,
        card: "",
        cvv: "",
        exp: ""
    },
    billing: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zip: ""
    },
    leadId: null
  }
  
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_PERSONAL:
            return {...state, personal: action.payload.personal}
        case SET_BILLING:
            return {...state, billing: action.payload.billing}
        case SET_LEAD_ID:
            return {...state, leadId: action.payload.leadId.leadId}
		default:
			return state;
	}
}