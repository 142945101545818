import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col } from 'shards-react';

class FloatingCart extends React.Component {

  state = {
    open: this.props.open
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return (
      <div className="promo-popup animated">
        <div onClick={this.toggleOpen} className="pp-intro-bar bg-primary">
          Cart - ${this.props.cart.reduce((accum, val) => {
                return parseInt(val.price) + parseInt(accum)
              }, 0)}

          {this.state.open ? <span className="close">
            <i className="material-icons">close</i>
              </span>: <span className="up">
                <i className="material-icons">keyboard_arrow_up</i>
              </span>}

        </div>
        {!this.state.open ? null : <div className="pp-inner-content hidden">
          {this.props.cart.map((item, i) => (
            <Row key={i} className="pt-1 pb-1">
              <Col md="8" xs="8">
                <p className="text-left mb-0">{item.title}</p>
              </Col>
              <Col md="4" xs="4">
                <p className="text-right mb-0">${item.price} {item.reOccur}</p>
              </Col>
            </Row>
          ))}
          <Row className="border-top pt-1 pb-1">
            <Col md="8" xs="8">
              <p className="m-0 font-weight-bold">Total</p>
            </Col>
            <Col>
              <p className="m-0 text-right font-weight-bold">${this.props.cart.reduce((accum, val) => {
                return parseInt(val.price) + parseInt(accum)
              }, 0)}</p>
            </Col>
          </Row>
        </div>}
        
      </div>
    )
  }
}

FloatingCart.propTypes = {
  cart: PropTypes.array,
  open: PropTypes.bool
};

FloatingCart.defaultProps = {
  cart: [],
  open: false
};

const mapStateToProps = state => ({
  cart: state.cart.cart
})

export default connect(mapStateToProps)(FloatingCart);
