import React from "react";
import PropTypes from "prop-types";
import { Collapse, Button } from "shards-react";

class CollapseItem extends React.Component {
  state = {
    open: false
  }

  toggleOpen = _ => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return(
      <>
        <p
          aria-controls={"collapse-text-" + (this.props.title.split(" ").join("-")).toLowerCase()}
          aria-expanded={this.state.open}
          className="mb-0 d-flex flex-wrap flex-sm-nowrap align-items-center w-100"
        >
          <span className="collapse-trigger d-flex align-items-center w-100" onClick={this.toggleOpen}>
            {this.state.open ? <i className="material-icons text-primary">keyboard_arrow_down</i> : <i className="material-icons text-primary">keyboard_arrow_right</i>}
            <i className="material-icons mr-2 ml-2 text-muted pricing_table_icon">{this.props.icon}</i>
            <strong>{this.props.title}</strong>
          </span>
          {(this.props.addable && this.props.hideAddButton !== true) ? (this.props.inCart() === true && this.props.hideAddButton !== true) ? 
            <Button outline size="sm" theme="danger" className="mb-0 mt-2 mx-auto mt-sm-0" onClick={() =>{this.props.removeFunc(this.props.id)}}>Remove</Button> :
            <Button outline size="sm" className="mb-0 mt-2 mx-auto mt-sm-0" onClick={() =>{this.props.addFunc(this.props.id)}}>Add</Button>
          : null}
        </p>
        <Collapse open={this.state.open}>
          <div id={"collapse-text-" + (this.props.title.split(" ").join("-")).toLowerCase()} className="ml-3">
            <p className="mt-2 mb-1 text-muted pricing_collapse_content">{this.props.content}</p>
          </div>
        </Collapse>
      </>
    )
  }
}

CollapseItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  addable: PropTypes.bool,
  inCart: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.number,
  addFunc: PropTypes.func,
  removeFunc: PropTypes.func,
  hideAddButton: PropTypes.any
};

CollapseItem.defaultProps = {
  title: "Make sure to pass a title as a prop",
  content: "Make sure to pass content as a prop",
  addable: true,
  inCart: () => false,
  icon: "close",
  id: 1,
  addFunc: (id) => {console.log("Add Function not set up on component correctly")},
  removeFunc: (id) => {console.log("Remove Function not set up on component correctly")},
  hideAddButton: false
};


export default CollapseItem;
