import React from "react";
import { 
  Container, 
  Row
} from "shards-react";

import ScrollToTop from "../components/common/ScrollToTop";
import PageTitle from "../components/common/PageTitle";
import BusinessInformation from "../components/components-overview/BussinessInformation/BusinessInformation";
import FloatingCart from "../components/components-overview/FloatingCart/FloatingCart";
import FacebookTracking from "../components/facebook-tracking/FacebookTracking";

const BusinessInformationView = () => (
  <Container fluid className="main-content-container pb-4">

    {/* facebook tracking pixel */}
    <FacebookTracking trackingString="Subscribe" />
    {/* handle scrolling to top */}
    <ScrollToTop />

    <Row noGutters className="page-header py-4">
      <PageTitle title="Business Information" subtitle="Enter basic business information" icon="add_business" className="text-sm-left" />
    </Row>
    <BusinessInformation />
    <FloatingCart />
  </Container>
);

export default BusinessInformationView;
