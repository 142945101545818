import React from "react";
import ReactPixel from "react-facebook-pixel";

class FacebookTracking extends React.Component {
  componentDidMount() {
    ReactPixel.trackCustom(this.props.trackingString);
  }
  render () {
    return null;
  }
}

export default FacebookTracking;