import React from "react";
import PropTypes from "prop-types";
import {
  FormInput, 
  FormFeedback
} from 'shards-react';

const TextInput = (props) => {

  return (
    <>
      <label className="d-none" htmlFor={`fe${props.name}`}>{props.label}</label>
      <FormInput
        type={props.type}
        id={`fe${props.name}`}
        placeholder={props.placeholder}
        required
        invalid={!props.valid}
        // this is a bandaid so that we can display successful async responses on text fields
        valid={props.success}
        name={props.name}
        value={props.value}
        onChange={props.onChangeFunc}
        onBlur={event => props.onBlurFunc(event, props.validationFunction)}
        autoComplete={props.autoComplete}
        maxLength={props.maxLength}
      />
      <FormFeedback>{props.formFeedback}</FormFeedback>
    </>
  )
};

TextInput.propTypes = {
  label: PropTypes.string,
  formFeedback: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  maxLength: PropTypes.string,
  validationFunction: PropTypes.func,
  onBlurFunc: PropTypes.func
};

TextInput.defaultProps = {
  label: "",
  formFeedback: "Please fill out the text input",
  placeholder: "",
  valid: false,
  success: false,
  value: "Test Name",
  type: "text",
  autoComplete: "",
  maxLength: "",
  validationFunction: () => {return true},
  onBlurFunc: () => {return true}
};

export default TextInput;