import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Container, Button } from "shards-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import NavForward from "../../common/NavForward";
import NavBackward from "../../common/NavBackward";

import { 
  setBilling, 
  setProcess, 
  setProgress, 
  setAccountId 
} from "../../../redux/actions";

class Processing extends React.Component {
  state = {
    complete: false
  }

  componentDidMount() {
    this.processPayment()
    this.props.setProgress(99)
  }

  processPayment = () => {

    // this section is to add a section that we add into the crm as tasks
    const taskListArray = [];
    let arrayId = 2;
    this.props.cart.forEach(item => {
      // only pull in the items from the cart that are actionable
      if(item.actionable === true) {
        taskListArray.push(item)
      }
      // set a number based on the package in the cart
      if(item.title.includes("Package")) {
        arrayId = item.id - 2
      }
    })
    // pull in the items that are included in the package
    this.props.addons.forEach(item => {
      if(item.prices[arrayId] === true && item.actionable === true) {
        taskListArray.push(item)
      }
    })


    // check to see if any items are missing on the request and throw an error
    const checkArray = [
      "firstName",
      "lastName",
      "address",
      "city",
      "persState",
      "zip",
      "card",
      "exp",
      "cvv",
      "firstNamePers",
      "lastNamePers",
      "addressPers",
      "cityPers",
      "persStatePers",
      "zipPers",
      "birthdayPers",
      "emailPers",
      "phonePers",
      "citizenPers",
      "llcSharePers",
      "llcName",
      "llcNameAlt",
      "llcState",
      "llcType",
      "llcFunction",
      "llcFunctionExplained"
    ]

    let validCheck = true;

    checkArray.forEach(item => {
      if(!this.props[item]) {
        validCheck = false
      }
    })

    if (validCheck) {
      axios.post(process.env.REACT_APP_API_URL, {
        bill_first: this.props.firstName,
        bill_last: this.props.lastName,
        bill_address: this.props.address,
        bill_city: this.props.city,
        bill_state: this.props.persState,
        bill_zip: this.props.zip,
        bill_card: this.props.card,
        bill_exp: this.props.exp,
        bill_cvv: this.props.cvv,
        pers_first: this.props.firstNamePers,
        pers_last: this.props.lastNamePers,
        pers_address: this.props.addressPers,
        pers_city: this.props.cityPers,
        pers_state: this.props.persStatePers,
        pers_zip: this.props.zipPers,
        pers_birthday: this.props.birthdayPers,
        pers_email: this.props.emailPers,
        pers_phone: this.props.phonePers,
        pers_citizen: this.props.citizenPers,
        pers_share: this.props.llcSharePers,
        llc_name: this.props.llcName,
        llc_name_alt: this.props.llcNameAlt,
        llc_name_alt_2: this.props.llcNameAlt2,
        llc_state: this.props.llcState,
        llc_type: this.props.llcType,
        llc_function: this.props.llcFunction,
        llc_function_explained: this.props.llcFunctionExplained,
        llc_members: this.props.llcMembers,
        cart: this.props.cart,
        leadId: this.props.leadId,
        promoCode: this.props.promoCode,
        taskList: taskListArray,
        appKey: 'tQAC9ZLu-X7aKR6evq-TryFMMsM5P-fLtF7Eaqq5-z6bh3GJpY2D'
      }).then(response => {
        if (response.status !== 200) {
          throw Error('We could not complete the transaction, please contact support to complete your purchase.')
        } else {
          this.setState({
            complete: true
          })

          this.props.setAccountId(response.data)
          // clear out the local storage
          localStorage.removeItem('incFastNowLeadID')
          localStorage.removeItem('sourceDescLeadId')
          // this is for the cached information from what the user entered
          // we dont want to keep this
          localStorage.removeItem('persist:main')
          localStorage.removeItem('persist:llc')

          // move them on to next page
          this.props.setBilling({
            card: "",
            exp: "",
            cvv: "",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zip: ""
          })
          this.props.setProcess({ in_process: false })
    
          NavForward(this.props);
        }
      }).catch(err => {
        this.setState({
          error: true
        })
      })
    } else {
      this.setState({
        error: true
      })
    }
  }

  render () {
    return (
      <Container fluid className="main-content-container p-0">
        <div className="error">
          <div className="error__content">
            {this.state.error ? <h2>Oops!</h2> : <h2>Processing</h2>}
            {this.state.error ? <p>We ran into an error. Please <a href="https://incfastnow.com/contact-us/" target="_blank" rel="noopener noreferrer">contact support</a> to complete your purchase or use the back button below to re-check your payment and billing details.</p> : <p>Please do not exit this page.</p>}
            {this.state.error ? <Button onClick={() => {NavBackward(this.props)}}>Back</Button> : null}
            
            {this.state.error ? null : <BeatLoader />}
            {process.env.NODE_ENV === 'development' ? <Button onClick={this.processPayment}>Retry</Button> : null}
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    // billing info
    firstName: state.personal.billing.firstName,
    lastName: state.personal.billing.lastName,
    address: state.personal.billing.address,
    city: state.personal.billing.city,
    persState: state.personal.billing.state,
    zip: state.personal.billing.zip,
    card: state.personal.billing.card,
    exp: state.personal.billing.exp,
    cvv: state.personal.billing.cvv,
    // cart
    cart: state.cart.cart,
    // personal values
    firstNamePers: state.personal.personal.firstName,
    lastNamePers: state.personal.personal.lastName,
    addressPers: state.personal.personal.address,
    cityPers: state.personal.personal.city,
    persStatePers: state.personal.personal.state,
    zipPers: state.personal.personal.zip,
    birthdayPers: state.personal.personal.birthday,
    emailPers: state.personal.personal.email,
    phonePers: state.personal.personal.phone,
    citizenPers: state.personal.personal.citizen,
    llcSharePers: state.personal.personal.llcShare,
    // LLC values
    llcName: state.llc.name,
    llcNameAlt: state.llc.alt_name,
    llcNameAlt2: state.llc.alt_name_2,
    llcState: state.llc.state,
    llcType: state.llc.type,
    llcFunction: state.llc.function,
    llcFunctionExplained: state.llc.functionExplained,
    llcMembers: state.llc.members,
    // nav
    nav: state.nav.navItems,
    // leads id
    leadId: state.personal.leadId,
    // promo code
    promoCode: state.cart.promoCode,
    // addons for creating new list of actionable items
    addons: state.cart.addons
  }
}

const mapDispatchToProps = dispatch => ({
  setBilling: emptyObj => dispatch(setBilling(emptyObj)),
  setProcess: llcProc => dispatch(setProcess(llcProc)),
  setProgress: progress => dispatch(setProgress(progress)),
  setAccountId: id => dispatch(setAccountId(id))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Processing));
