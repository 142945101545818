import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import cart from "./cart";
import nav from "./nav";
import personal from "./personal";
import llc from "./llc";

const persistConfig = {
    key: 'main',
    storage,
    blacklist: ['personal', 'llc', 'cart']
}

const llcPersistConfig = {
    key: 'llc',
    storage,
    blacklist: ['members']
}

const cartpersistConfig = {
    key: 'cart',
    storage,
    blacklist: ['addons', 'filingFees', 'packages']
}

const rootReducer = combineReducers({ cart: persistReducer(cartpersistConfig, cart), nav, personal, llc: persistReducer(llcPersistConfig, llc) });

export default persistReducer(persistConfig, rootReducer)
