import React from "react";
import { 
  Container, 
  Row, 
  Col 
} from "shards-react";

import ScrollToTop from "../components/common/ScrollToTop";
import PageTitle from "../components/common/PageTitle";
import PricingTable from "../components/components-overview/PricingTable/PricingTable";
import FloatingCart from "../components/components-overview/FloatingCart/FloatingCart";
import FacebookTracking from "../components/facebook-tracking/FacebookTracking";

const PricingView = () => (
  <Container fluid className="main-content-container pb-4">

    {/* facebook tracking pixel */}
    <FacebookTracking trackingString="AchieveLevel" />
    {/* handle scrolling to top */}
    <ScrollToTop />

    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Welcome" subtitle="Select the package you would like" icon="storefront" className="text-sm-left" />
    </Row>
    <Row>
      <Col>
        <p>Welcome to Inc Fast now! You can begin your business creation here. If you have any questions during the process, dont hesitate to reach out and <a href="https://incfastnow.com/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a>!</p>
      </Col>
    </Row>
    <PricingTable />
    <FloatingCart />
  </Container>
);

export default PricingView;

