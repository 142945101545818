import React from "react";
import { 
  Container, 
  Row
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Referral from "../components/components-overview/Referral/Referral";

const ReferralView = () => (
  <Container fluid className="main-content-container pb-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Referral" subtitle="Enter in your referral information" icon="phone" className="text-sm-left" />
    </Row>
    <Referral />
  </Container>
);

export default ReferralView;
