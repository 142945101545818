import React from "react";
import axios from "axios";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button
} from "shards-react";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import { setPromoCode, setDiscountAmount } from "../../../redux/actions";

import TextInput from "../../form-items/TextInput";

class PromoCode extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    promoCode: this.props.promoCode,
    promoCodeValid: true,
    promoCodeSuccess: false,
    loading: false
  }

  componentDidMount() {
    if(this.state.promoCode !== null && this.state.promoCode !== "") {
      this.applyPromo()
    }
  }

  handleChange(event) {
    const myStateObj = {};
    myStateObj[event.target.name] = event.target.value;
    this.setState(myStateObj)
  }

  applyPromo = () => {
    this.setState({
      loading: true
    })
    // set the promo code in the state
    this.props.setPromoCode(this.state.promoCode)

    axios.post(`${process.env.REACT_APP_API_URL}/promo`, {
      promoCode: this.state.promoCode,
      appKey: 'tQAC9ZLu-X7aKR6evq-TryFMMsM5P-fLtF7Eaqq5-z6bh3GJpY2D'
    }).then(resp => {
      if(resp.data.discount === false) throw new Error()
      this.setState({
        promoCodeValid: true,
        promoCodeSuccess: true,
        loading: false
      })
      // set the discount in the store
      this.props.setDiscountAmount(resp.data.discount)
    }).catch(err => {
      this.setState({
        promoCodeValid: false,
        promoCodeSuccess: false,
        loading: false
      })
      this.props.setDiscountAmount(null)
    })
  }

  render() {
    return (
      <Card small className="mb-4">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row className="mb-2">
              <Col>
                <TextInput 
                  label="Promo Code" 
                  name="promoCode"
                  placeholder="Code"
                  formFeedback="Invalid promo code"
                  valid={this.state.promoCodeValid}
                  success={this.state.promoCodeSuccess}
                  value={this.state.promoCode || ""}
                  onChangeFunc={this.handleChange}
                />
              </Col>
            </Row>
            {this.state.loading ? <BeatLoader /> : <Button className="mb-2 mr-1" outline onClick={this.applyPromo}>Apply</Button> }
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    promoCode: state.cart.promoCode
  }
}

const mapDispatchToProps = dispatch => ({
  setPromoCode: promoCode => dispatch(setPromoCode(promoCode)),
  setDiscountAmount: discount => dispatch(setDiscountAmount(discount))
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
