import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const PageTitle = ({ title, subtitle, className, icon, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="6" className={classes} { ...attrs }>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <h3 className="page-title"><i className="material-icons">{icon}</i> {title}</h3>
    </Col>
  )
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string
};

PageTitle.defaultProps = {
  title: "Welcome",
  subtitle: "Fill me in",
  icon: "close"
};

export default PageTitle;
