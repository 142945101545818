import navItems from "../../NavigationItems";

import { SET_PROGRESS } from "../actionTypes";

const initialState = {
	navItems: navItems,
	progress: 10
  }
  
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_PROGRESS:
			return { ...state, progress: action.payload.progress }
		default:
			return state;
	}
}