import React from "react";
import PropTypes from "prop-types";
import wNumb from "wnumb";
import {
  Slider
} from 'shards-react';

const ShareSlider = (props) => {

  return (
    <>
      <label className="mb-3" htmlFor={`fe${props.name}`}>{props.label} - Must equal at least 99% over all sliders</label>
      <Slider
        theme="primary"
        className="my-4"
        connect={[true, false]}
        start={props.start}
        range={{ min: 1, max: 100 }}
        step={1}
        tooltips={[wNumb({decimals: 0})]}
        name="llcShare"
        onChange={props.onChange}
      />
    </>
  )
};

ShareSlider.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  start: PropTypes.array
};

ShareSlider.defaultProps = {
  label: "Business Percentage Share",
  name: "llcShare",
  value: "",
  start: [100]
};

export default ShareSlider;