import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainFooter from "../components/layout/MainFooter";
import Progress from "../components/common/ProgressBar";

const DefaultLayout = ({ children, noFooter }) => (
  <Container fluid>
    <Row>
      {/* <NavbarToggle />
      <MainSidebar /> */}

      <Col
        className="main-content p-0 mt-3"
        lg={{ size: 10, offset: 1 }}
        md="12"
        tag="main"
      >
        <Progress />
        {children}
        
      </Col>
    </Row>
    <Row>
      <Col className="p-0">
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noFooter: false
};

export default DefaultLayout;
