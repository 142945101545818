import React from "react";
import PropTypes from "prop-types";
import {
  FormSelect,
  FormFeedback
} from 'shards-react';

const CorpTypeSelect = (props) => {

  return (
    <>
      <label className="d-none" htmlFor={`fe${props.name}`}>{props.label}</label>
      <FormSelect 
        id={`fe${props.name}`}
        name={props.name} 
        value={props.value}
        invalid={!props.valid} 
        onChange={props.onChangeFunc}
        onBlur={event => props.onBlurFunc(event, props.validationFunction)}
        >
        <option value="">Business Type</option>
        <option value="LLC">LLC</option>
        <option value="C-Corp">C-Corp</option>
        <option value="S-Corp">S-Corp</option>
        {/* <option value="DBA">DBA</option> */}
      </FormSelect>
      <FormFeedback>{props.formFeedback}</FormFeedback>
    </>
  )
};

CorpTypeSelect.propTypes = {
  label: PropTypes.string,
  formFeedback: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  validationFunction: PropTypes.func
};

CorpTypeSelect.defaultProps = {
  label: "Business Type",
  formFeedback: "Please select your business type",
  valid: false,
  value: "",
  validationFunction: () => {return true}
};

export default CorpTypeSelect;