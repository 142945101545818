import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
 
import persistedReducer from './reducers/index';

const reduxComps = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose;
 

export default () => {
    let store = createStore(persistedReducer, compose(applyMiddleware(thunk), reduxComps))
    let persistor = persistStore(store)
    return { store, persistor }
}
