import React from "react";
import PropTypes from "prop-types";
import {
  FormFeedback,
  FormInput
} from 'shards-react';

const CorpFunctionSelect = (props) => {

  return (
    <>
      <label className="d-none" htmlFor={`fe${props.name}`}>{props.label}</label>
      <FormInput
        type={props.type}
        id={`fe${props.name}`}
        placeholder={props.placeholder}
        required
        invalid={!props.valid}
        name={props.name}
        value={props.value}
        onChange={props.onChangeFunc}
        onBlur={event => props.onBlurFunc(event, props.validationFunction)}
        list="function-list"
      />
      <datalist id="function-list" >
        <option value="">Bussiness Function</option>
        <option value="Accommodations">Accommodations</option>
        <option value="Construction">Construction</option>
        <option value="Consulting">Consulting</option>
        <option value="Entertainment">Entertainment</option>
        <option value="Finance">Finance</option>
        <option value="Food Services">Food Services</option>
        <option value="Health Care">Health Care</option>
        <option value="Insurance">Insurance</option>
        <option value="Manufacturing">Manufacturing</option>
        <option value="Professional">Professional</option>
        <option value="Real Estate">Real Estate</option>
        <option value="Rental and Leasing">Rental and Leasing</option>
        <option value="Retail">Retail</option>
        <option value="Sales">Sales</option>
        <option value="Technology">Technology</option>
        <option value="Transportation">Transportation</option>
        <option value="Warehousing">Warehousing</option>
        <option value="Wholesale">Wholesale</option>
      </datalist>
      <FormFeedback>{props.formFeedback}</FormFeedback>
    </>
  )
};

CorpFunctionSelect.propTypes = {
  label: PropTypes.string,
  formFeedback: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  validationFunction: PropTypes.func
};

CorpFunctionSelect.defaultProps = {
  label: "What will your business do? (type in or select)",
  formFeedback: "Please tell us what your business will do",
  placeholder: "What will your business do? (type in or select)",
  valid: false,
  value: "",
  type: "text",
  validationFunction: () => {return true}
};

export default CorpFunctionSelect;