import React from "react";
import { Row, Col } from "shards-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Cart from "../CartInformation/CartInformation";
import CalculateDiscount from "../../common/CalculateDiscount";

import { setProgress } from "../../../redux/actions";

class Complete extends React.Component {

  state = {
    cartTotal: 0
  }

  componentDidMount() {
    // if(this.props.in_process) {
    //   this.props.history.replace('/welcome')
    // }
    this.props.setProgress(100)
    const discountObj = CalculateDiscount(this.props.cart, this.props.discount)
    this.setState(discountObj)

  }

  render() {
    return(
      <>
        <Row>
          <Col md="3"></Col>
          <Col md="6">
            <p className="text-center">Thank you for your purchase! We will send a receipt within the next 15 minutes. Please make sure to double check your spam folder if you do not see it in your inbox. If you have any issues, please <a target="_blank" rel="noopener noreferrer" href="https://incfastnow.com/contact-us">contact us</a>.</p>
            <br />
            <p className="text-center">Please be aware that the charge on your card may show as "Advanced Tax Group".</p>
          </Col>
          <Col md="3"></Col>
        </Row>
        <Row>
          <Col md="3"></Col>
          <Col md="6">
            <Cart title="Order Summary" cartItems={this.props.cart} cartTotal={this.state.cartTotal} discount={this.props.discount} />
          </Col>
          <Col md="3"></Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">
              <a href="https://incfastnow.com">Home</a>
            </p>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return { 
    cart: state.cart.cart,
    in_process: state.llc.in_process,
    nav: state.nav.navItems,
    discount: state.cart.discount
  }
}

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgress(progress))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Complete));
