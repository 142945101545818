import React from "react";
import { Container, Row } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ScrollToTop from "../components/common/ScrollToTop";
import CompleteMessage from "../components/components-overview/CompleteMessage/CompleteMessage"
import FacebookTracking from "../components/facebook-tracking/FacebookTracking";
import DataLayer from "../components/common/DataLayer";

const CompleteView = () => (
  <Container fluid className="main-content-container pb-4">
    {/* this sends some data layer information */}
    <DataLayer />

    {/* facebook tracking pixel */}
    <FacebookTracking trackingString="Purchase" />
    {/* handle scrolling to top */}
    <ScrollToTop />
  
    <Row noGutters className="page-header py-4">
      <PageTitle title="Order Complete!" subtitle="Your business creation is now in process" icon="check" className="text-sm-left" />
    </Row>
    <CompleteMessage />
  </Container>
)


export default CompleteView;

