import { SET_LLC, SET_LLC_INTRO, SET_LLC_PROCESS, SET_MEMBERS } from "../actionTypes";

const initialState = {
    name: "",
    alt_name: "",
    alt_name_2: "",
    state: "",
    type: "",
    function: "",
    functionExplained: "",
    in_process: true,
    members: []
  }
  
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_LLC:
      return { 
        ...state, 
        name: action.payload.llc.name, 
        alt_name: action.payload.llc.alt_name,
        alt_name_2: action.payload.llc.alt_name_2,
        function: action.payload.llc.function, 
        functionExplained: action.payload.llc.functionExplained,
        type: action.payload.llc.type, 
        state: action.payload.llc.state
      }
    case SET_LLC_INTRO:
      return { ...state, type: action.payload.llc.type, state: action.payload.llc.state }
    case SET_LLC_PROCESS: 
      return { ...state, in_process: action.payload.llc.in_process }
    case SET_MEMBERS:
      return { ...state, members: action.payload.members }
		default:
			return state;
	}
}