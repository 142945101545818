import { SET_CART, SET_DISCOUNT_AMOUNT, SET_PROMO_CODE, SET_SELECTED_PACKAGE, SET_ACCOUNT_ID } from '../actionTypes';
import filingFees from './info/filingFees';
import addons from './info/addons';
import packages from './info/packages';

const initialState = {
	cart: [],
	packages,
	addons,
	filingFees,
	discount: null,
	promoCode: "",
	selectedPackage: null,
	accountId: null
  }
  
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_CART: {
			return { ...state, cart: action.payload.cart }
		}
		case SET_SELECTED_PACKAGE: {
			return { ...state, selectedPackage: action.payload.selectedPackage }
		}
		case SET_DISCOUNT_AMOUNT: {
			return { ...state, discount: action.payload.discount }
		}
		case SET_PROMO_CODE: {
			return { ...state, promoCode: action.payload.promoCode }
		}
		case SET_ACCOUNT_ID: {
			return { ...state, accountId: action.payload.accountId }
		}
		default:
			return state;
	}
}