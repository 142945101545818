export default function BirthdayValidation(value) {
    let valid = true;
    const today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    if(value >= yesterday || value === null || value === "") {
        valid = false;
    }
    if(value <= new Date("1/1/1900")){
        valid = false;
    }
    return valid;
}