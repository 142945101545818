import { 
  SET_CART,
  SET_PERSONAL,
  SET_BILLING,
  SET_LLC,
  SET_LLC_INTRO,
  SET_LLC_PROCESS,
  SET_PROGRESS,
  SET_LEAD_ID,
  SET_MEMBERS,
  SET_PROMO_CODE,
  SET_DISCOUNT_AMOUNT,
  SET_SELECTED_PACKAGE,
  SET_ACCOUNT_ID
} from './actionTypes';

export const setCart = content => ({
  type: SET_CART,
  payload: {
    cart: content
  }
})

export const setSelectedPackage = content => ({
  type: SET_SELECTED_PACKAGE,
  payload: {
    selectedPackage: content
  }
})

export const setPersonal = content => ({
  type: SET_PERSONAL,
  payload: {
    personal: content
  }
})

export const setBilling = content => ({
  type: SET_BILLING,
  payload: {
    billing: content
  }
})

export const setLlc = content => ({
  type: SET_LLC,
  payload: {
    llc: content
  }
})

export const setLlcIntro = content => ({
  type: SET_LLC_INTRO,
  payload: {
    llc: content
  }
})

export const setMembers = content => ({
  type: SET_MEMBERS,
  payload: {
    members: content
  }
})

export const setProcess = content => ({
  type: SET_LLC_PROCESS,
  payload: {
    llc: content
  }
})

export const setProgress = content => ({
  type: SET_PROGRESS,
  payload: {
    progress: content
  }
})

export const setLeadId = content => ({
  type: SET_LEAD_ID,
  payload: {
    leadId: content
  }
})

export const setPromoCode = content => ({
  type: SET_PROMO_CODE,
  payload: {
    promoCode: content
  }
})

export const setDiscountAmount = content => ({
  type: SET_DISCOUNT_AMOUNT,
  payload: {
    discount: content
  }
})

export const setAccountId = content => ({
  type: SET_ACCOUNT_ID,
  payload: {
    accountId: content
  }
})