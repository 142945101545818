export default {
  AL: {
    LLC: {
      fee: 150,
      expedite: 100
    },
    corp: {
      fee: 150,
      expedite: 100
    }
  },
  AK: {
    LLC: {
      fee: 250
    },
    corp: {
      fee: 250
    }
  },
  AZ: {
    LLC: {
      fee: 50,
      expedite: 35
    },
    corp: {
      fee: 60,
      expedite: 35
    }
  },
  AR: {
    LLC: {
      fee: 50
    },
    corp: {
      fee: 50
    }
  },
  CA: {
    LLC: {
      fee: 85
    },
    corp: {
      fee: 100,
      expedite: 500
    }
  },
  CO: {
    LLC: {
      fee: 50
    },
    corp: {
      fee: 50
    }
  },
  CT: {
    LLC: {
      fee: 120,
      expedite: 50
    },
    corp: {
      fee: 250,
      expedite: 50
    }
  },
  DE: {
    LLC: {
      fee: 90,
      expedite: 200
    },
    corp: {
      fee: 90,
      expedite: 200
    }
  },
  DC: {
    LLC: {
      fee: 220,
      expedite: 100
    },
    corp: {
      fee: 220,
      expedite: 100
    }
  },
  FL: {
    LLC: {
      fee: 125
    },
    corp: {
      fee: 70
    }
  },
  GA: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 100
    }
  },
  HI: {
    LLC: {
      fee: 51,
      expedite: 25
    },
    corp: {
      fee: 51,
      expedite: 25
    }
  },
  ID: {
    LLC: {
      fee: 100,
      expedite: 20
    },
    corp: {
      fee: 120,
      expedite: 20
    }
  },
  IL: {
    LLC: {
      fee: 250
    },
    corp: {
      fee: 275
    }
  },
  IN: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 100
    }
  },
  IA: {
    LLC: {
      fee: 50
    },
    corp: {
      fee: 50
    }
  },
  KS: {
    LLC: {
      fee: 165
    },
    corp: {
      fee: 85
    }
  },
  KY: {
    LLC: {
      fee: 40
    },
    corp: {
      fee: 50
    }
  },
  LA: {
    LLC: {
      fee: 100,
      expedite: 50
    },
    corp: {
      fee: 75,
      expedite: 50
    }
  },
  ME: {
    LLC: {
      fee: 175,
      expedite: 100
    },
    corp: {
      fee: 145,
      expedite: 100
    }
  },
  MD: {
    LLC: {
      fee: 150
    },
    corp: {
      fee: 170
    }
  },
  MA: {
    LLC: {
      fee: 500,
      expedite: 25
    },
    corp: {
      fee: 275,
      expedite: 25
    }
  },
  MI: {
    LLC: {
      fee: 50,
      expedite: 200
    },
    corp: {
      fee: 60,
      expedite: 200
    }
  },
  MN: {
    LLC: {
      fee: 155
    },
    corp: {
      fee: 155
    }
  },
  MS: {
    LLC: {
      fee: 50
    },
    corp: {
      fee: 50
    }
  },
  MO: {
    LLC: {
      fee: 50
    },
    corp: {
      fee: 58
    }
  },
  MT: {
    LLC: {
      fee: 70,
      expedite: 100
    },
    corp: {
      fee: 70,
      expedite: 100
    }
  },
  NE: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 60
    }
  },
  NV: {
    LLC: {
      fee: 425
    },
    corp: {
      fee: 425
    }
  },
  NH: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 100
    }
  },
  NJ: {
    LLC: {
      fee: 125,
      expedite: 200
    },
    corp: {
      fee: 100,
      expedite: 200
    }
  },
  NM: {
    LLC: {
      fee: 50,
      expedite: 150
    },
    corp: {
      fee: 100,
      expedite: 150
    }
  },
  NY: {
    LLC: {
      fee: 200,
      expedite: 150
    },
    corp: {
      fee: 135,
      expedite: 150
    }
  },
  NC: {
    LLC: {
      fee: 125,
      expedite: 200
    },
    corp: {
      fee: 125,
      expedite: 200
    }
  },
  ND: {
    LLC: {
      fee: 135
    },
    corp: {
      fee: 100
    }
  },
  OH: {
    LLC: {
      fee: 99,
      expedite: 300
    },
    corp: {
      fee: 99,
      expedite: 300
    }
  },
  OK: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 50
    }
  },
  OR: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 100
    }
  },
  PA: {
    LLC: {
      fee: 125
    },
    corp: {
      fee: 125
    }
  },
  PR: {
    LLC: {
      fee: 250
    },
    corp: {
      fee: 150
    }
  },
  RI: {
    LLC: {
      fee: 150
    },
    corp: {
      fee: 230
    }
  },
  SC: {
    LLC: {
      fee: 110
    },
    corp: {
      fee: 135
    }
  },
  SD: {
    LLC: {
      fee: 150,
      expedite: 50
    },
    corp: {
      fee: 150,
      expedite: 50
    }
  },
  TN: {
    LLC: {
      fee: 300,
    },
    corp: {
      fee: 100
    }
  },
  TX: {
    LLC: {
      fee: 300,
    },
    corp: {
      fee: 300
    }
  },
  UT: {
    LLC: {
      fee: 70,
      expedite: 75
    },
    corp: {
      fee: 70,
      expedite: 75
    }
  },
  VT: {
    LLC: {
      fee: 125
    },
    corp: {
      fee: 125
    }
  },
  VA: {
    LLC: {
      fee: 75,
      expedite: 200
    },
    corp: {
      fee: 75,
      expedite: 200
    }
  },
  WA: {
    LLC: {
      fee: 200,
    },
    corp: {
      fee: 200
    }
  },
  WV: {
    LLC: {
      fee: 100,
    },
    corp: {
      fee: 130
    }
  },
  WI: {
    LLC: {
      fee: 130,
      expedite: 25
    },
    corp: {
      fee: 100,
      expedite: 25
    }
  },
  WY: {
    LLC: {
      fee: 100
    },
    corp: {
      fee: 100
    }
  }
}
