import React from "react";
import PropTypes from "prop-types";
import {
  FormSelect,
  FormFeedback
} from 'shards-react';

const ReferralSelect = (props) => {

  return (
    <>
      <label htmlFor={`fe${props.name}`}>{props.label}</label>
      <FormSelect 
        id={`fe${props.name}`}
        name={props.name} 
        value={props.value}
        invalid={!props.valid} 
        onChange={props.onChangeFunc}
        onBlur={event => props.onBlurFunc(event, props.validationFunction)}
        >
        <option value="">Choose</option>
        <option value="Entity">Entity</option>
        <option value="Tax">Tax</option>
        <option value="Bookkeeping">Bookkeeping</option>
        <option value="Estate Planning">Estate Planning</option>
        <option value="Corporate Credit">Corporate Credit</option>
      </FormSelect>
      <FormFeedback>{props.formFeedback}</FormFeedback>
    </>
  )
};

ReferralSelect.propTypes = {
  label: PropTypes.string,
  formFeedback: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  validationFunction: PropTypes.func
};

ReferralSelect.defaultProps = {
  label: "Client Need Type",
  formFeedback: "Please select the client need",
  valid: false,
  value: "",
  validationFunction: () => {return true}
};

export default ReferralSelect;