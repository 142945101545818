import React from "react";
import PropTypes from "prop-types";
import {
  DatePicker,
  FormFeedback
} from 'shards-react';

const DatePickerInput = (props) => {

  return (
    <>
      <label htmlFor={`fe${props.name}`}>{props.label}</label>
      <br />
      <DatePicker 
        className={!props.valid ? "is-invalid" : null}
        name={props.name}
        id={`fe${props.name}`}
        invalid={!props.valid} 
        placeholderText={props.placeholder} 
        selected={props.value} 
        onChange={date => props.onChangeFunc(date)}
        // spoofing the event target for our validation function
        onSelect={value => props.onBlurFunc({target: { value: value, name: props.name }}, props.validationFunction)}
        onBlur={event => props.onBlurFunc(event, props.validationFunction)}
        maxDate={props.maxDate}
        minDate={props.minDate}
        showYearDropdown
      />
      <FormFeedback className={!props.valid ? "d-block" : null}>{props.formFeedback}</FormFeedback>
    </>
  )
};

DatePickerInput.propTypes = {
  label: PropTypes.string,
  formFeedback: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  validationFunction: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date)
};

DatePickerInput.defaultProps = {
  label: "Birthday",
  formFeedback: "Please enter your birthday",
  placeholder: "MM/DD/YYYY",
  valid: false,
  value: null,
  validationFunction: () => {return true},
  maxDate: null,
  minDate: null
};

export default DatePickerInput;