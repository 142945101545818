import React, { Component } from "react";
import GoogleAnalytics from "react-ga";
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';

if(process.env.NODE_ENV === "production") {
  // google analytics
  GoogleAnalytics.initialize(process.env.REACT_APP_GAID);
  // google tag manager
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM
  })
  // facebook pixel
  ReactPixel.init("288583842377239", {}, { debug: false, autoConfig: true })
}


const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // adding facebook react pixel
      ReactPixel.pageView();
      // google track page
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(`${page}`);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(`${nextPage}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
