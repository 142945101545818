import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "shards-react";

const Cart = ({ title, cartItems, cartTotal, discount }) => (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3 font-weight-normal">
        {cartItems.length > 0 ? cartItems.map((item, index) => (
          <Row key={index}>
            <Col md="12">
              <Row >
                <Col md="8" xs="8">
                    <p className="text-left mb-0 font-weight-bold">{item.title}</p>
                </Col>
                <Col md="4" xs="4">
                    <p className="text-right mb-0 font-weight-bold">${item.price} {item.reOccur}</p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p className="text-muted">{item.description}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          )) : <p className="text-muted">Your cart is empty</p>}
      </ListGroupItem>
      {discount ? <ListGroupItem className="p-3 font-weight-normal">
        <Row>
          <Col md="8" xs="8">
              <p className="text-left mb-0 font-weight-bold">Promo discount</p>
          </Col>
          <Col md="4" xs="4">
            <p className="text-right mb-0 font-weight-bold">{parseInt(discount) > 1 ? `-$${discount}` : `-${parseFloat(discount) * 100}%`}</p>
          </Col>
        </Row>
      </ListGroupItem> : null}
    </ListGroup>
    <CardFooter>
      <Row>
        <Col md="6">
          <h6 className="m-0">Total</h6>
        </Col>
        <Col md="6">
          <p className="m-0 text-right">${cartTotal.toFixed(2)}</p>  
        </Col>
      </Row>
    </CardFooter>
  </Card>
);

Cart.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  cartItems: PropTypes.array,
  cartTotal: PropTypes.number
};

Cart.defaultProps = {
  title: "Cart",
  cartItems: [],
  cartTotal: 0
};

export default Cart;
