// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";
import NavItems from "./NavigationItems";
import Referral from "./views/Referral";

const pathArray = [];

// add the referral so it doesnt show up in the menu
pathArray.push({
  path: "/referral",
  exact: true,
  layout: EmptyLayout,
  component: Referral
})

// for each nav item add the component
NavItems.forEach(item => {
  pathArray.push({
    path: item.route,
    exact: true,
    layout: DefaultLayout,
    component: item.component
  })
})

export default pathArray;