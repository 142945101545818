import BusinessInformation from "./views/BusinessInformation";
import Pricing from "./views/Pricing";
import Checkout from "./views/Checkout";
import Processing from "./views/Processing";
import Complete from "./views/Complete";

export default [{
    title: "Choose Your Package",
    active: false,
    complete: true,
    route: '/welcome',
    component: Pricing,
    formPart: true
  },
  {
    title: "Business Information",
    active: false,
    complete: false,
    route: '/business-information',
    component: BusinessInformation,
    formPart: true
  },
  {
    title: "Checkout",
    active: false,
    complete: false,
    route: '/checkout',
    component: Checkout,
    formPart: true
  },
  {
    title: "Processing",
    active: false,
    complete: false,
    route: '/processing',
    component: Processing
  },
  {
    title: "Complete",
    active: false,
    complete: false,
    route: '/complete',
    component: Complete
  }
]
